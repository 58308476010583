<template>
  <svg
    height="100px"
    width="100px"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 55 55"
    enable-background="new 0 0 55 55"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M31.875,37.3089371h-8.8800049c-7,0-12.7000122,5.1700439-12.7000122,11.5300293v1.6599731c0,0.5500488,0.4500122,1,1,1    h32.2799683c0.5500488,0,1-0.4499512,1-1v-1.6599731C44.5749512,42.478981,38.8849487,37.3089371,31.875,37.3089371z"
        ></path>
      </g>
      <g>
        <path
          d="M43.6098022,17.4435787v-1.006958c0-7.1328115-6.7832031-12.9355469-15.1206055-12.9355469h-2.1074219    c-8.3374023,0-15.1206055,5.8027349-15.1206055,12.9355469v1.0429077c-2.1281128,0.5612793-3.696167,2.3462524-3.696167,4.4794312    c0,1.7089844,1.0111084,3.1918945,2.5032959,4.0087891v0.1153564c0,2.1137695,1.8603516,3.8330078,4.1474609,3.8330078h9.8809204    c0.5733032,1.0982666,1.7774658,1.862854,3.1782837,1.862854c1.9500122,0,3.5300293-1.4700317,3.5300293-3.2800293    s-1.5800171-3.2799683-3.5300293-3.2799683c-1.7349243,0-3.1776123,1.1657715-3.4763184,2.6971436h-9.5828857    c-0.9752808,0-1.7915039-0.5612183-2.0523071-1.3228149c0.1641846,0.0147095,0.3231812,0.0456543,0.4915161,0.0456543h0.9199829    c0.5500488,0,1-0.4500122,1-1v-7.3599854c0-0.5499878-0.4499512-1-1-1h-0.3137817v-0.8423462    c0-6.0297842,5.8857422-10.9355469,13.1206055-10.9355469h2.1074219c7.2348633,0,13.1206055,4.9057622,13.1206055,10.9355469    v0.8423462H41.414978c-0.5499878,0-1,0.4500122-1,1v7.3599854c0,0.5499878,0.4500122,1,1,1h0.9299927    c2.7999878,0,5.0900269-2.0999756,5.0900269-4.6799927C47.4349976,19.7833004,45.7992554,17.9651852,43.6098022,17.4435787z"
        ></path>
      </g>
      <g>
        <path
          d="M27.2748413,33.7789078c-1.687439,0-3.1986694-0.7250366-4.2156372-1.8627949h-2.1820068    c1.8597412,1.7534199,4.0957031,2.9228535,6.5578003,2.9228535c6.8199463,0,11.9499512-8.9000263,11.9499512-14.6599751    c0-5.9900503-5.3599854-10.8600454-11.9499512-10.8600454c-6.5900269,0-11.9500122,4.8699951-11.9500122,10.8600454    c0,1.7370605,0.4735718,3.7591553,1.3123169,5.7371216h5.6506958c0.9508667-1.6075439,2.7566528-2.6972656,4.8268433-2.6972656    c3.0493164,0,5.5302734,2.3686523,5.5302734,5.2802734C32.8051147,31.4102535,30.3241577,33.7789078,27.2748413,33.7789078z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>

<style></style>
