<template>
  <div class="relative min-h-screen bg-gray-100">
    <main class="py-2">
      <!-- Page header -->
      <SuccessBanner
        :key="key"
        :msg="successMsg"
        :active="activeBanner"
        @closeBanner="activeBanner = false"
        :isSuccess="isSuccess"
        @bannerTimer="activeBanner = false"
      />
      <div
        class="mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:px-8"
      >
        <div class="flex items-center space-x-0">
          <div class="flex-shrink-0">
            <!-- <div class="relative">
              <img
                class="h-16 w-16 rounded-full"
                src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                alt=""
              />
              <span
                class="absolute inset-0 shadow-inner rounded-full"
                aria-hidden="true"
              />
            </div> -->
          </div>
          <div>
            <h1 class="text-2xl font-bold text-gray-900">
              {{ customerData.name }}
            </h1>
            <p class="text-sm font-medium text-gray-500">
              Account
              <a href="#" class="text-gray-900">{{
                customerData.customerAccount
              }}</a>
              <!-- <time datetime="2020-08-25">August 25, 2020</time> -->
            </p>
          </div>
        </div>
        <div
          class="mt-2 mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-3"
        >
          <span class="relative z-0 inline-flex rounded-md">
            <button
              @click="showActive = 'tickets'"
              type="button"
              :class="[
                showActive === 'tickets'
                  ? 'body__button text-white'
                  : 'bg-white text-gray-700',
                'relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm font-medium hover:bg-gray-50 focus:z-10 encom_primary_border_focus',
              ]"
            >
              Tickets
            </button>
            <button
              @click="showActive = 'files'"
              type="button"
              :class="[
                showActive === 'files'
                  ? 'body__button text-white'
                  : 'bg-white text-gray-700',
                '-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium hover:bg-gray-50 focus:z-10 encom_primary_border_focus',
              ]"
            >
              Files
            </button>
            <button
              @click="showActive = 'invoices'"
              type="button"
              :class="[
                showActive === 'invoices'
                  ? 'body__button text-white'
                  : 'bg-white text-gray-700',
                '-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm font-medium hover:bg-gray-50 focus:z-10 encom_primary_border_focus',
              ]"
            >
              Invoices
            </button>
          </span>
        </div>
        <div
          class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3"
        >
          <div v-for="supplier in this.suppliers" :key="supplier">
            <span
              class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800"
            >
              {{ supplier }}
            </span>
          </div>
        </div>
      </div>

      <div
        class="mt-8 mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-4"
      >
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
          <!-- Description list-->
          <section aria-labelledby="applicant-information-title">
            <div class="bg-white shadow sm:rounded-lg">
              <div class="px-4 py-5 sm:px-6">
                <h2
                  id="applicant-information-title"
                  class="text-lg leading-6 font-medium text-gray-900 capitalize"
                >
                  Customer Information
                </h2>
                <!-- <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  Personal details and application.
                </p> -->
              </div>
              <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                      Address
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ customerData.mainAddressLine1 }},
                      {{ customerData.mainAddressPostalCode }}
                    </dd>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ customerData.mainAddressCity }}
                    </dd>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ customerData.mainAddressCountry }}
                    </dd>
                  </div>
                  <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                      Email address
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ customerData.emailAddress }}
                    </dd>
                    <dd
                      class="mt-1 text-sm text-gray-900"
                      v-show="!customerData.emailAddress"
                    >
                      Empty
                    </dd>
                  </div>
                  <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                      Phone
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ customerData.phone }}
                    </dd>
                  </div>
                  <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                      Account Email address
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ customerData.accountEmailAddress }}
                    </dd>
                    <dd
                      class="mt-1 text-sm text-gray-900"
                      v-show="!customerData.accountEmailAddress"
                    >
                      Empty
                    </dd>
                  </div>

                  <div class="sm:col-span-2">
                    <dt class="text-sm font-medium text-gray-500">
                      About
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <span v-html="customerData.comment"></span>
                    </dd>
                  </div>
                  <!-- <div class="sm:col-span-2">
                    <dt class="text-sm font-medium text-gray-500">
                      Documents
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <ul
                        role="list"
                        class="border border-gray-200 rounded-md divide-y divide-gray-200"
                        v-if="!showAllDocuments"
                      >
                        <li
                          v-for="attachment in attachments"
                          :key="attachment.name"
                          class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                        >
                          <div class="w-0 flex-1 flex items-center">
                            <PaperClipIcon
                              class="flex-shrink-0 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span class="ml-2 flex-1 w-0 truncate">
                              {{ attachment.name }}
                            </span>
                          </div>
                          <div class="ml-4 flex-shrink-0">
                            <a
                              :href="attachment.href"
                              class="font-medium text-blue-600 hover:text-blue-500"
                            >
                              Download
                            </a>
                          </div>
                        </li>
                      </ul>
                      <ul
                        role="list"
                        class="border border-gray-200 rounded-md divide-y divide-gray-200"
                        v-if="showAllDocuments"
                      >
                        <li
                          v-for="attachment in allAttachments"
                          :key="attachment.name"
                          class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                        >
                          <div class="w-0 flex-1 flex items-center">
                            <PaperClipIcon
                              class="flex-shrink-0 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span class="ml-2 flex-1 w-0 truncate">
                              {{ attachment.name }}
                            </span>
                          </div>
                          <div class="ml-4 flex-shrink-0">
                            <a
                              :href="attachment.href"
                              class="font-medium text-blue-600 hover:text-blue-500"
                            >
                              Download
                            </a>
                          </div>
                        </li>
                      </ul>
                    </dd>
                  </div> -->
                </dl>
              </div>
              <!-- <div>
                <a
                  v-if="!showAllDocuments"
                  @click="showAllDocuments = true"
                  href="#"
                  class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg"
                  >Show all</a
                >
              </div>
              <div>
                <a
                  v-if="showAllDocuments"
                  @click="showAllDocuments = false"
                  href="#"
                  class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg"
                  >Hide</a
                >
              </div> -->
            </div>
          </section>
          <div
            class="bg-white pt-5 shadow sm:rounded-lg divide-y divide-gray-300"
          >
            <div
              class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap px-4"
            >
              <div class="ml-4 mt-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Contacts
                </h3>
              </div>
              <div class="ml-4 mt-2 flex-shrink-0">
                <button
                  type="button"
                  class="relative inline-flex items-center px-1 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button focus:outline-none "
                >
                  <PlusSmIcon class="h-5 w-5" aria-hidden="true" />

                  <!--  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3 w-3"
                    viewBox="0 0 20 20"
                   fill="currentColor"
                   >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                   </svg> -->
                </button>
              </div>
            </div>
            <div class="bg-white overflow-hidden rounded-b-md mt-2">
              <ul role="list" class="divide-y divide-gray-200">
                <li v-for="contact in contacts" :key="contact.id">
                  <a class="block hover:bg-gray-50">
                    <div class="flex items-center px-4 py-4">
                      <div class="min-w-0 flex-1 flex items-center">
                        <div
                          class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4"
                        >
                          <div>
                            <p
                              class="text-sm font-medium body__table--body__text truncate"
                            >
                              {{ contact.firstName }} {{ contact.lastName }}
                              {{ contact.id }}
                            </p>
                            <p
                              class="mt-2 flex items-center text-sm text-gray-500"
                            >
                              <MailIcon
                                class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span class="truncate">{{
                                contact.emailAddress
                              }}</span>
                            </p>
                          </div>
                          <div
                            class="hidden md:block"
                            v-if="contact.mobilePhone"
                          >
                            <div>
                              <p
                                class="mt-2 flex items-center text-sm text-gray-500"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                  />
                                </svg>
                                {{ contact.mobilePhone }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="inline-flex px-4 text-gray-600">
                        <a
                          @click="
                            this.$router.push(
                              `/open-ticket/${contact.contactable_id}/${contact.id}`
                            )
                          "
                          title="Open ticket"
                          class="p-1 shadow-sm border-2 border-gray-500 rounded-md hover:bg-gray-200 cursor-pointer tooltip"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                            />
                          </svg>
                        </a>
                      </div>
                      <div>
                        <ChevronRightIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            :class="[
              activeBanner && isSuccess && activeBannerFor === 'host'
                ? 'border-green-400 border-2'
                : activeBanner && activeBannerFor === 'host' && !isSuccess
                ? 'border-2 border-red-400'
                : '',
              'bg-white pt-5 shadow sm:rounded-lg divide-y divide-gray-300',
            ]"
          >
            <div
              class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap px-4"
            >
              <div class="ml-4 mt-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Hosts
                </h3>
              </div>
              <div class="ml-4 mt-2 flex-shrink-0">
                <button
                  @click="goToNewHost()"
                  type="button"
                  class="relative inline-flex items-center px-1 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button focus:outline-none "
                >
                  <PlusSmIcon class="h-5 w-5" aria-hidden="true" />

                  <!--  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3 w-3"
                    viewBox="0 0 20 20"
                   fill="currentColor"
                   >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                   </svg> -->
                </button>
              </div>
            </div>
            <ReducedListTemplate
              :dataList="hosts"
              :isHost="true"
              :isInfra="false"
              @activeBanner="
                (activeBanner = true),
                  getHosts(),
                  (key = !key),
                  (activeBannerFor = 'host')
              "
              @successMsg="successMsg = $event"
              @success="isSuccess = $event"
            />
          </div>
          <div
            :class="[
              activeBanner && isSuccess && activeBannerFor === 'infra'
                ? 'border-green-400 border-2'
                : activeBanner && !isSuccess && activeBannerFor === 'infra'
                ? 'border-2 border-red-400'
                : '',
              'bg-white pt-5 shadow sm:rounded-lg divide-y divide-gray-300',
            ]"
          >
            <div
              class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap px-4"
            >
              <div class="ml-4 mt-2">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900 capitalize"
                >
                  infrastructures
                </h3>
              </div>
              <div class="ml-4 mt-2 flex-shrink-0">
                <button
                  @click="goToNewInfrastructure()"
                  type="button"
                  :class="[
                    'relative inline-flex items-center px-1 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button focus:outline-none',
                  ]"
                >
                  <PlusSmIcon class="h-5 w-5" aria-hidden="true" />

                  <!--  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3 w-3"
                    viewBox="0 0 20 20"
                   fill="currentColor"
                   >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                   </svg> -->
                </button>
              </div>
            </div>
            <ReducedListTemplate
              :dataList="infrastructures"
              :isHost="false"
              :isInfra="true"
              @activeBanner="
                (activeBanner = true),
                  getInfrastructures(),
                  (key = !key),
                  (activeBannerFor = 'infra')
              "
              @successMsg="successMsg = $event"
              @success="isSuccess = $event"
            />
          </div>
          <!-- <div
            class="bg-white pt-5 shadow sm:rounded-lg divide-y divide-gray-300"
          >
            <div
              class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap px-4"
            >
              <div class="ml-4 mt-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Documents
                </h3>
                
              </div>
              <div class="ml-4 mt-2 flex-shrink-0">
                <input type="file" id="myFile" name="filename" />
                <button
                  type="button"
                  class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button focus:outline-none "
                >
                  Send
                </button>
              </div>
            </div>
            <div class="bg-white overflow-hidden rounded-b-md mt-2">
              <ul role="list" class="divide-y divide-gray-200">
                <li
                  v-for="application in applications"
                  :key="application.applicant.email"
                >
                  <a :href="application.href" class="block hover:bg-gray-50">
                    <div class="flex items-center px-4 py-4">
                      <div class="min-w-0 flex-1 flex items-center">
                        <div
                          class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4"
                        >
                          <div>
                            <p
                              class="text-sm font-medium text-indigo-600 truncate"
                            >
                              {{ application.applicant.name }}
                            </p>
                            <p
                              class="mt-2 flex items-center text-sm text-gray-500"
                            >
                              <MailIcon
                                class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span class="truncate">{{
                                application.applicant.email
                              }}</span>
                            </p>
                          </div>
                          <div class="hidden md:block">
                            <div>
                              <p class="text-sm text-gray-900">
                                Applied on
                                {{ " " }}
                                <time :datetime="application.date">{{
                                  application.dateFull
                                }}</time>
                              </p>
                              <p
                                class="mt-2 flex items-center text-sm text-gray-500"
                              >
                                <CheckCircleIcon
                                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                  aria-hidden="true"
                                />
                                {{ application.stage }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div> -->
          <!-- Comments-->
        </div>

        <section
          aria-labelledby="timeline-title"
          class="lg:col-start-3 lg:col-span-2"
        >
          <div class="space-y-6">
            <!-- <div
              class="bg-white pt-5 shadow sm:rounded-lg divide-y divide-gray-300"
            >
              <div
                class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap px-4"
              >
                <div class="ml-4 mt-2">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Files
                  </h3>
                  
                </div>
                <div class="ml-4 mt-2 flex-shrink-0">
                  <button
                    type="button"
                    class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button focus:outline-none "
                  >
                    New file
                  </button>
                </div>
              </div>
              <TablePagination rows="5" :tableData="applications" />
            </div> -->
            <!-- <section aria-labelledby="notes-title">
              <div class="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                <div class="divide-y divide-gray-200">
                  <div class="px-4 py-5 sm:px-6">
                    <h2
                      id="notes-title"
                      class="text-lg font-medium text-gray-900"
                    >
                      Notes
                    </h2>
                  </div>
                  <div class="px-4 py-6 sm:px-6">
                    <ul role="list" class="space-y-8">
                      <li v-for="comment in comments" :key="comment.id">
                        <div class="flex space-x-3">
                          <div>
                            <div class="text-sm">
                              <a href="#" class="font-medium text-gray-900">{{
                                comment.name
                              }}</a>
                            </div>
                            <div class="mt-1 text-sm text-gray-700">
                              <p>{{ comment.body }}</p>
                            </div>
                            <div class="mt-2 text-sm space-x-2">
                              <span class="text-gray-500 font-medium">{{
                                comment.date
                              }}</span>
                              {{ " " }}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="bg-gray-50 px-4 py-6 sm:px-6">
                  <div class="flex space-x-3">
                    <div class="min-w-0 flex-1">
                      <form action="#">
                        <div>
                          <label for="comment" class="sr-only">About</label>
                          <textarea
                            id="comment"
                            name="comment"
                            rows="3"
                            class="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                            placeholder="Add a note"
                          />
                        </div>
                        <div class="mt-3 flex items-center justify-between">
                          <a
                            href="#"
                            class="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900"
                          >
                            <QuestionMarkCircleIcon
                              class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            <span>
                              Some HTML is okay.
                            </span>
                          </a>
                          <button
                            type="submit"
                            class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white body__button focus:outline-none"
                          >
                            Comment
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section> -->

            <div
              v-show="showActive === 'tickets'"
              :class="[
                activeBanner && isSuccess && activeBannerFor === 'openTickets'
                  ? 'border-2 border-green-400'
                  : activeBanner &&
                    !isSuccess &&
                    activeBannerFor === 'openTickets'
                  ? 'border-2 border-red-400'
                  : '',
                'bg-white pt-5 shadow sm:rounded-lg divide-y divide-gray-300',
              ]"
            >
              <div
                class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap px-4"
              >
                <div class="ml-4 mt-2">
                  <h3
                    class="text-lg leading-6 font-medium text-gray-900 capitalize"
                  >
                    Open tickets
                  </h3>
                  <!-- <p class="mt-1 text-sm text-gray-500">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit quam
                    corrupti consectetur.
                  </p> -->
                </div>
                <div class="ml-1 flex-shrink-0">
                  <!-- <button
                    type="button"
                    class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button focus:outline-none "
                  >
                    New ticket
                  </button> -->
                  <SearchFilter
                    v-model:search="searchOpenTicket"
                    :inputPlaceholder="$t('header.searchTicketPlaceHolder')"
                  />
                </div>
              </div>
              <PaginationOpenTicketList
                rows="5"
                :tableData="filteredTickets()"
                @activeBanner="
                  (activeBanner = true),
                    getOpenTickets(),
                    getTicketsClosed(),
                    (key = !key),
                    (activeBannerFor = 'openTickets')
                "
                @successMsg="successMsg = $event"
                @success="isSuccess = $event"
              />
            </div>

            <div
              v-show="showActive === 'tickets'"
              :class="[
                activeBanner && isSuccess && activeBannerFor === 'closedTickets'
                  ? 'border-2 border-green-400'
                  : activeBanner &&
                    !isSuccess &&
                    activeBannerFor === 'closedTickets'
                  ? 'border-2 border-red-400'
                  : '',
                'bg-white pt-5 shadow sm:rounded-lg divide-y divide-gray-300',
              ]"
              v-if="closedTickets.length"
            >
              <div
                class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap px-4"
              >
                <div class="ml-4 mt-2">
                  <h3
                    class="text-lg leading-6 font-medium text-gray-900 capitalize"
                  >
                    Closed Tickets
                  </h3>
                  <!-- <p class="mt-1 text-sm text-gray-500">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit quam
                    corrupti consectetur.
                  </p> -->
                </div>
                <div class="ml-4 mt-2 flex-shrink-0">
                  <!-- <button
                    type="button"
                    class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button focus:outline-none "
                  >
                    New ticket
                  </button> -->
                </div>
              </div>
              <OpenTicketsList
                v-if="backendPagination"
                :tablePaginatedData="closedTickets"
                :backendPagination="backendPagination"
                :to="closedTicketsTo"
                :from="closedTicketsFrom"
                :total="closedTicketsTotal"
                :lastPage="closedTicketsLastPage"
                @firstPage="firstPage"
                @previousPage="previousPage"
                @nextPage="nextPage"
                @lastPage="goLastPage"
                @activeBanner="
                  (activeBanner = true),
                    getTicketsClosed(),
                    getOpenTickets(),
                    (key = !key),
                    (activeBannerFor = 'closedTickets')
                "
                @successMsg="successMsg = $event"
                @success="isSuccess = $event"
              />
            </div>

            <!-- <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
              <h2 id="timeline-title" class="text-lg font-medium text-gray-900">
                Invoices
              </h2>

              <div class="mt-6 flow-root">
                <ul role="list" class="-mb-8">
                  <li v-for="(item, itemIdx) in timeline" :key="item.id">
                    <div class="relative pb-8">
                      <span
                        v-if="itemIdx !== timeline.length - 1"
                        class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                      <div class="relative flex space-x-3">
                        <div>
                          <span
                            :class="[
                              item.type.bgColorClass,
                              'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                            ]"
                          >
                            <component
                              :is="item.type.icon"
                              class="w-5 h-5 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <div
                          class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4"
                        >
                          <div>
                            <p class="text-sm text-gray-500">
                              {{ item.content }}
                              <a href="#" class="font-medium text-gray-900">{{
                                item.target
                              }}</a>
                            </p>
                          </div>
                          <div
                            class="text-right text-sm whitespace-nowrap text-gray-500"
                          >
                            <time :datetime="item.datetime">{{
                              item.date
                            }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="mt-6 flex flex-col justify-stretch">
                <button
                  type="button"
                  class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Advance to offer
                </button>
              </div>
            </div> -->
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
} from "@heroicons/vue/solid";

import TablePagination from "../../components/TablePagination.vue";
import PaginationOpenTicketList from "../../components/tickets/PaginationOpenTicketList.vue";
import HoverTooltip from "../../components/HoverTooltip.vue";
import SuccessBanner from "../../components/SuccessBanner.vue";
import TicketTable from "../../components/tickets/TicketTable.vue";
import OpenTicketsList from "../../components/tickets/OpenTicketsList.vue";
import SearchFilter from "../../components/SearchFilter.vue";
import SupportIcon from "../../assets/icons/SupportIcon.vue";
import ReducedListTemplate from "../../components/ReducedListTemplate.vue";

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  ArrowNarrowLeftIcon,
  CheckIcon,
  HomeIcon,
  PaperClipIcon,
  PlusSmIcon,
  QuestionMarkCircleIcon,
  SearchIcon,
  ThumbUpIcon,
  UserIcon,
} from "@heroicons/vue/solid";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/vue/outline";

const user = {
  name: "Whitney Francis",
  email: "whitney@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
};
const navigation = [
  { name: "Dashboard", href: "#" },
  { name: "Jobs", href: "#" },
  { name: "Applicants", href: "#" },
  { name: "Company", href: "#" },
];
const breadcrumbs = [
  { name: "Jobs", href: "#", current: false },
  { name: "Front End Developer", href: "#", current: false },
  { name: "Applicants", href: "#", current: true },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];
const attachments = [
  { name: "resume_front_end_developer.pdf", href: "#" },
  { name: "coverletter_front_end_developer.pdf", href: "#" },
];
const allAttachments = [
  { name: "resume_front_end_developer.pdf", href: "#" },
  { name: "coverletter_front_end_developer.pdf", href: "#" },
  { name: "coverletter_front_end_developer.pdf", href: "#" },
  { name: "coverletter_front_end_developer.pdf", href: "#" },
  { name: "coverletter_front_end_developer.pdf", href: "#" },
  { name: "coverletter_front_end_developer.pdf", href: "#" },
  { name: "coverletter_front_end_developer.pdf", href: "#" },
  { name: "coverletter_front_end_developer.pdf", href: "#" },
  { name: "coverletter_front_end_developer.pdf", href: "#" },
  { name: "coverletter_front_end_developer.pdf", href: "#" },
  { name: "coverletter_front_end_developer.pdf", href: "#" },
  { name: "coverletter_front_end_developer.pdf", href: "#" },
];
const eventTypes = {
  applied: { icon: UserIcon, bgColorClass: "bg-gray-400" },
  advanced: { icon: ThumbUpIcon, bgColorClass: "bg-blue-500" },
  completed: { icon: CheckIcon, bgColorClass: "bg-green-500" },
};
const timeline = [
  {
    id: 1,
    type: eventTypes.applied,
    content: "Applied to",
    target: "Front End Developer",
    date: "Sep 20",
    datetime: "2020-09-20",
  },
  {
    id: 2,
    type: eventTypes.advanced,
    content: "Advanced to phone screening by",
    target: "Bethany Blake",
    date: "Sep 22",
    datetime: "2020-09-22",
  },
  {
    id: 3,
    type: eventTypes.completed,
    content: "Completed phone screening with",
    target: "Martha Gardner",
    date: "Sep 28",
    datetime: "2020-09-28",
  },
  {
    id: 4,
    type: eventTypes.advanced,
    content: "Advanced to interview by",
    target: "Bethany Blake",
    date: "Sep 30",
    datetime: "2020-09-30",
  },
  {
    id: 5,
    type: eventTypes.completed,
    content: "Completed interview with",
    target: "Katherine Snyder",
    date: "Oct 4",
    datetime: "2020-10-04",
  },
];
const comments = [
  {
    id: 1,
    name: "Leslie Alexander",
    date: "4d ago",
    imageId: "1494790108377-be9c29b29330",
    body:
      "Ducimus quas delectus ad maxime totam doloribus reiciendis ex. Tempore dolorem maiores. Similique voluptatibus tempore non ut.",
  },
  {
    id: 2,
    name: "Michael Foster",
    date: "4d ago",
    imageId: "1519244703995-f4e0f30006d5",
    body:
      "Et ut autem. Voluptatem eum dolores sint necessitatibus quos. Quis eum qui dolorem accusantium voluptas voluptatem ipsum. Quo facere iusto quia accusamus veniam id explicabo et aut.",
  },
  {
    id: 3,
    name: "Dries Vincent",
    date: "4d ago",
    imageId: "1506794778202-cad84cf45f1d",
    body:
      "Expedita consequatur sit ea voluptas quo ipsam recusandae. Ab sint et voluptatem repudiandae voluptatem et eveniet. Nihil quas consequatur autem. Perferendis rerum et.",
  },
];
const applications = [
  {
    applicant: {
      name: "Ricardo Cooper",
      email: "ricardo.cooper@example.com",
      imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    date: "2020-01-07",
    dateFull: "January 7, 2020",
    stage: "Completed phone screening",
    href: "#",
  },
  {
    applicant: {
      name: "Kristen Ramos",
      email: "kristen.ramos@example.com",
      imageUrl:
        "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    date: "2020-01-07",
    dateFull: "January 7, 2020",
    stage: "Completed phone screening",
    href: "#",
  },
  {
    applicant: {
      name: "Ted Fox",
      email: "ted.fox@example.com",
      imageUrl:
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    date: "2020-01-07",
    dateFull: "January 7, 2020",
    stage: "Completed phone screening",
    href: "#",
  },
  {
    applicant: {
      name: "Ted Fox",
      email: "ted.fox@example.com",
      imageUrl:
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    date: "2020-01-07",
    dateFull: "January 7, 2020",
    stage: "Completed phone screening",
    href: "#",
  },
  {
    applicant: {
      name: "Ted Fox",
      email: "ted.fox@example.com",
      imageUrl:
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    date: "2020-01-07",
    dateFull: "January 7, 2020",
    stage: "Completed phone screening",
    href: "#",
  },
  {
    applicant: {
      name: "Ted Fox",
      email: "ted.fox@example.com",
      imageUrl:
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    date: "2020-01-07",
    dateFull: "January 7, 2020",
    stage: "Completed phone screening",
    href: "#",
  },
  {
    applicant: {
      name: "Ted Fox",
      email: "ted.fox@example.com",
      imageUrl:
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    date: "2020-01-07",
    dateFull: "January 7, 2020",
    stage: "Completed phone screening",
    href: "#",
  },
  {
    applicant: {
      name: "Ted Fox",
      email: "ted.fox@example.com",
      imageUrl:
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    date: "2020-01-07",
    dateFull: "January 7, 2020",
    stage: "Completed phone screening",
    href: "#",
  },
  {
    applicant: {
      name: "Ted Fox",
      email: "ted.fox@example.com",
      imageUrl:
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    date: "2020-01-07",
    dateFull: "January 7, 2020",
    stage: "Completed phone screening",
    href: "#",
  },
];

export default {
  props: ["id"],
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverOverlay,
    PopoverPanel,
    PlusSmIcon,
    TransitionChild,
    TransitionRoot,
    ArrowNarrowLeftIcon,
    BellIcon,
    HomeIcon,
    MenuIcon,
    PaperClipIcon,
    QuestionMarkCircleIcon,
    SearchIcon,
    XIcon,
    CheckCircleIcon,
    ChevronRightIcon,
    MailIcon,
    TablePagination,
    PaginationOpenTicketList,
    HoverTooltip,
    SuccessBanner,
    TicketTable,
    OpenTicketsList,
    SearchFilter,
    SupportIcon,
    ReducedListTemplate,
  },
  data() {
    return {
      customerData: {},
      user,
      navigation,
      breadcrumbs,
      userNavigation,
      attachments,
      allAttachments,
      eventTypes,
      timeline,
      comments,
      showAllDocuments: false,
      suppliers: [],
      applications,
      contacts: [],
      openTickets: [],
      hosts: [],
      infrastructures: [],
      showHosts: [
        "data.name",
        "data.customer.name",
        "data.code",
        "data.address",
      ],
      showActive: "tickets",
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      page: 1,
      closedTickets: [],
      closedTicketsTo: "",
      closedTicketsFrom: "",
      closedTicketsTotal: "",
      closedTicketsLastPage: "",
      currentPage: 1,
      searchOpenTicket: "",
      key: false,
      activeBannerFor: "",
    };
  },
  methods: {
    goToNewHost() {
      let routeData = this.$router.resolve({
        path: `/hosting/newHost/${this.id}`,
      });
      window.open(routeData.href, "_blank");
    },
    goToNewInfrastructure() {
      let routeData = this.$router.resolve({
        path: `/hosting/new-infrastructure/${this.id}`,
      });
      window.open(routeData.href, "_blank");
    },
    async getCustomer() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/customers/${this.id}`
        );
        this.customerData = res.data;
        this.suppliers = res.data.suppliers
          .split('"')
          .join(",")
          .split("[")
          .join(",")
          .split("]")
          .join(",")
          .split(",");
        this.suppliers = this.suppliers.filter(function(sup) {
          return sup !== "";
        });
      } catch (error) {
        this.errorHandling(error);
        this.errorHandling(error);
      }
    },
    async getContacts() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/contacts?contactable_id=${
            this.id
          }&contactable_type=App\\customer`
        );
        this.contacts = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getOpenTickets() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie(
            "API"
          )}/api/v1/tickets?filter[ticketable_id]=${this.id}`
        );
        this.openTickets = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    filteredTickets() {
      return this.openTickets.filter((ticket) => {
        return (
          ticket.subject
            .toLowerCase()
            .includes(this.searchOpenTicket.toLowerCase()) ||
          this.formatDate(ticket.created_at)
            .toLowerCase()
            .includes(this.searchOpenTicket.toLowerCase()) ||
          this.formatDate(ticket.updated_at)
            .toLowerCase()
            .includes(this.searchOpenTicket.toLowerCase())
        );
      });
    },
    async getTicketsClosed(page) {
      this.closedTickets.length = 0;
      this.isLoading = true;
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie(
            "API"
          )}/api/v1/ticketsClosed?filter[ticketable_id]=${this.id}&page=${page}`
        );
        if (res) {
          this.backendPagination = true;
          this.isLoading = false;
          this.closedTickets = res.data.data;
          this.closedTicketsTo = res.data.to;
          this.closedTicketsFrom = res.data.from;
          this.closedTicketsTotal = res.data.total;
          this.closedTicketsLastPage = res.data.last_page;
        }
      } catch (error) {
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    firstPage() {
      let firstPage = 1;
      this.currentPage = firstPage;
      this.getTicketsClosed(firstPage);
    },
    nextPage() {
      let nextPage = parseInt(this.currentPage) + 1;
      this.currentPage = nextPage;
      this.getTicketsClosed(nextPage);
      this.$forceUpdate();
    },
    previousPage() {
      let previousPage = null;
      if (this.currentPage > 1) {
        previousPage = parseInt(this.currentPage) - 1;
      } else {
        previousPage = parseInt(this.currentPage);
      }
      this.currentPage = previousPage;
      this.getTicketsClosed(previousPage);
      this.$forceUpdate();
    },
    goLastPage() {
      this.currentPage = this.closedTicketsLastPage;
      this.getTicketsClosed(this.closedTicketsLastPage);
      this.$forceUpdate();
    },
    async getHosts() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hosts?filter[customer_id]=${
            this.id
          }`
        );
        this.hosts = res.data;
        this.isLoading = false;
      } catch (error) {
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    async getInfrastructures() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie(
            "API"
          )}/api/v1/infrastructures?filter[customer_id]=${this.id}`
        );
        this.infrastructures = res.data;
        this.isLoading = false;
      } catch (error) {
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getCustomer();
    this.getContacts();
    this.getOpenTickets();
    this.getTicketsClosed(this.currentPage);
    this.getHosts();
    this.getInfrastructures();
  },
  watch: {
    id: function(val) {
      this.getContacts();
      this.getCustomer();
      this.getOpenTickets();
      this.getTicketsClosed();
      this.getHosts();
      this.getInfrastructures();
    },
  },
};
</script>

<style></style>
