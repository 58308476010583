<template>
  <OpenTicketsList
    v-if="this.$route.name === 'CustomerDetail'"
    :tablePaginatedData="this.pagination().querySet"
    @activeBanner="$emit('activeBanner')"
    @successMsg="$emit('successMsg', $event)"
    @success="$emit('success', $event)"
  />

  <nav
    class="bg-white px-4 py-3 flex items-center justify-between border-t rounded-b-md border-gray-200"
    aria-label="Pagination"
  >
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        {{ $t("ticketTable.showing") }}
        {{ " " }}
        <span class="font-medium">{{
          this.rows * this.page - this.rows + 1
        }}</span>
        {{ " " }}
        {{ $t("ticketTable.to") }}
        {{ " " }}
        <span class="font-medium">{{ getPageLength() }} </span>
        {{ " " }}
        {{ $t("ticketTable.of") }}
        {{ " " }}
        <span class="font-medium">{{ this.tableData.length }}</span>
        {{ " " }}
        {{ $t("ticketTable.results") }}
      </p>
    </div>
    <div class="sm:px-10">
      <select
        @change="setRows($event)"
        id="location"
        name="location"
        class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm "
      >
        <option :value="this.rows">{{ $t("ticketTable.selectRows") }}</option>
        <option v-for="numb in this.selectRows" :key="numb" :value="numb"
          >{{ numb }} {{ $t("ticketTable.rows") }}</option
        >
        <option :value="this.tableData.length">{{
          $t("ticketTable.showAll")
        }}</option>
      </select>
    </div>
    <div class="flex-1 flex sm:justify-end pt-3 sm:pt-0">
      <a
        href="javascript:void(0)"
        @click="this.page = 1"
        class="rounded ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table-pagination--button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-3.5 w-3.5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
          />
        </svg>
      </a>
      <a
        href="javascript:void(0)"
        @click="previousPage"
        class="rounded ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table-pagination--button"
      >
        {{ $t("ticketTable.previous") }}
      </a>

      <a
        href="javascript:void(0)"
        @click="nextPage"
        class="rounded ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table-pagination--button"
      >
        {{ $t("ticketTable.next") }}
      </a>
      <a
        href="javascript:void(0)"
        @click="this.page = this.pagination().pages"
        class="rounded ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-white body__table-pagination--button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-3.5 w-3.5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 5l7 7-7 7M5 5l7 7-7 7"
          />
        </svg>
      </a>
    </div>
  </nav>
</template>

<script>
import OpenTicketsList from "./OpenTicketsList.vue";

export default {
  name: "TablePagination",
  props: [
    "tableData",
    "preview",
    "previewData",
    "documentHeaderName",
    "previewTableHeader",
    "id",
    "callqueueTable",
    "rows",
  ],
  components: {
    OpenTicketsList,
  },
  data() {
    return {
      page: 1,
      selectedRows: null,
      selectRows: [5, 10, 15, 20, 25, 30],
    };
  },
  methods: {
    changePage(event) {
      this.page = event.target.value;
    },
    nextPage() {
      if (this.page < this.pagination().pages) {
        this.page = parseInt(this.page) + 1;
      } else {
        this.page = this.page;
      }
    },
    previousPage() {
      if (this.page > 1) {
        this.page = parseInt(this.page) - 1;
      } else {
        this.page = this.page;
      }
    },
    getPageLength() {
      if (this.page === this.pagination().pages) {
        return this.tableData.length;
      } else {
        if (this.selectedRows) {
          return this.selectedRows * this.page;
        } else {
          return this.rows * this.page;
        }
      }
    },
    setRows(event) {
      this.selectedRows = event.target.value;
    },
    pagination() {
      if (this.selectedRows) {
        let trimStart = (this.page - 1) * parseInt(this.selectedRows);
        let trimEnd = trimStart + parseInt(this.selectedRows);

        let trimmedData = this.tableData.slice(trimStart, trimEnd);

        let pages = Math.ceil(this.tableData.length / this.selectedRows);
        return {
          querySet: trimmedData,
          pages: pages,
        };
      } else {
        let trimStart = (this.page - 1) * parseInt(this.rows);
        let trimEnd = trimStart + parseInt(this.rows);

        let trimmedData = this.tableData.slice(trimStart, trimEnd);

        let pages = Math.ceil(this.tableData.length / this.rows);
        return {
          querySet: trimmedData,
          pages: pages,
        };
      }
    },
  },
};
</script>

<style></style>
