<template>
  <div class="bg-white overflow-hidden rounded-b-md mt-2">
    <ul role="list" class="divide-y divide-gray-200" v-if="dataList.length > 0">
      <li v-for="(data, idx) in dataList" :key="data.id">
        <a class="block hover:bg-gray-50">
          <div class="flex items-center px-4 py-4">
            <div class="min-w-0 flex-1 flex items-center">
              <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p
                    class="text-sm font-medium body__table--body__text truncate"
                  >
                    {{ data.name }}
                  </p>
                  <p class="mt-2 flex items-center text-sm text-gray-500">
                    <span class="truncate">{{ data.customer.name }}</span>
                  </p>
                </div>
                <div>
                  <p
                    class="text-sm font-medium body__table--body__text truncate"
                  >
                    {{ data.code }}
                  </p>
                  <p class="mt-2 flex items-center text-sm text-gray-500">
                    <span class="truncate">{{ data.address }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div v-if="isHost">
              <UpdateHost
                :id="data.id"
                :customerId="data.customer_id"
                @activeBanner="$emit('activeBanner')"
                @success="$emit('success', $event)"
                @successMsg="$emit('successMsg', $event)"
              />
            </div>
            <div v-if="isInfra">
              <UpdateInfrastructure
                :id="data.id"
                :customerId="data.customer_id"
                @activeBanner="$emit('activeBanner')"
                @success="$emit('success', $event)"
                @successMsg="$emit('successMsg', $event)"
                @openEditForm="editCategory(idx)"
              />
            </div>
          </div>
        </a>
        <EditInfrastructure
          @closeForm="showEdit = false"
          :key="infrastructureId"
          v-if="String(this.showIdx) === String(idx) && this.showEdit"
          :infrastructureId="data.id"
          @activeBanner="$emit('activeBanner')"
          @success="$emit('success', $event)"
          @successMsg="$emit('successMsg', $event)"
          @closeHostView="showEdit = false"
        />
      </li>
    </ul>
    <div v-else class="justify-center text-center my-8">
      <svg
        class="mx-auto h-16 w-16 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vector-effect="non-scaling-stroke"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
        />
      </svg>
      <h3 class="mt-2 text-xl font-medium text-gray-900">
        No data
      </h3>
      <p class="mt-1 text-base text-gray-500">
        You don't have any data at the moment.
      </p>
    </div>
  </div>
</template>

<script>
import UpdateHost from "./hosting/UpdateHost.vue";
import UpdateInfrastructure from "./hosting/UpdateInfrastructure.vue";
import EditInfrastructure from "./hosting/EditInfrastructure.vue";

export default {
  props: ["dataList", "showData", "isHost", "isInfra"],
  components: {
    UpdateHost,
    UpdateInfrastructure,
    EditInfrastructure,
  },
  data() {
    return {
      showEdit: false,
      infrastructureId: "",
      showIdx: "",
    };
  },
  methods: {
    editCategory(idx) {
      if (this.showIdx !== "" && idx != this.showIdx && this.showEdit) {
        this.showIdx = idx;
        this.showEdit = true;
      } else {
        this.showEdit = !this.showEdit;
        this.showIdx = idx;
      }
    },
  },
};
</script>

<style></style>
